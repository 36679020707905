body {
  font-family: 'Source Code Pro', monospace;
  background-color: #1e1e1e;
  color: #d4d4d4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.todo-list {
  background-color: #252526;
  padding: 10px;
  border-radius: 5px;
}

.todo-item {
  background-color: #2d2d2d;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 3px;
}

.todo-item input {
  background-color: #3c3c3c;
  color: #d4d4d4;
  border: none;
  padding: 5px;
  width: 100%;
}
